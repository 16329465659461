<template>
  <div id="app" class="p-event">
    <vue-draggable-resizable :parent="true" v-if="$store.state.gameInfo" :w="260" :h="80" style="z-index: 99"
      :disable-user-select="false" :resizable="false">
      <div class="fixed-box">
        <div class="game-img">
          <img :src="$store.state.gameInfo.gameImg" fit="cover" alt="" />
          <div class="conduct">进行中...</div>
        </div>
        <div class="game-info">
          <div class="game-name ellipsis">{{ $store.state.gameInfo.gameName }}</div>
          <div>
            <el-button size="mini" @click="backups($store.state.gameInfo.GameID, $store.state.gameInfo.steamgameid)" v-if="$store.state.gameInfo.backups">
              备份存档
            </el-button>
            <el-button size="mini" @click="$router.push(`/gameDetail/${$store.state.gameInfo.productId}`)">进入游戏</el-button>
          </div>
        </div>
      </div>
    </vue-draggable-resizable>

    <el-container>
      <!-- 头部网吧信息 -->
      <el-header>
        <VHeader />
      </el-header>

      <el-container>
        <!-- 左侧菜单栏 -->
        <el-aside width="120px">
          <VMenu ref="menu" />
        </el-aside>

        <!-- 内容区域 -->
        <el-main>
          <router-view :key="this.$route.path" />
        </el-main>
      </el-container>
    </el-container>

    <!-- 游戏存档名 -->
    <!-- <el-dialog title="保存存档" :visible.sync="showGameNameDialog" width="400px" custom-class="gameNameDialog"
      :close-on-click-modal="false" :before-close="closeDialog">
      <el-form :inline="true" :model="form">
        <el-form-item label="存档名称：">
          <el-input v-model.trim="form.gameName" placeholder="请输入存档名称"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import VHeader from "@/views/components/VHeader.vue";
import VMenu from "@/views/components/VMenu.vue";
import cMethods from "@/util/cMethods.js";
import { exportForCMethods } from "@/util/exportForCMethods.js"

import {
  GetSteamPower,
  GetVerifyGame,
  GetUserWelfareLogs,
  GetList,
} from "@/api";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["userInfo", "isLogin"]),
  },
  mixins: [exportForCMethods],
  components: {
    VHeader,
    VMenu,
  },
  data() {
    return {
      isPackUp: false, // 是否收起左侧菜单
      netBarName: "", //网吧名
      netBarId: "", //网吧ID
      // showGameNameDialog: false,
      // form: {
      //   gameName: "",
      // },
      // gameInfo: null,
      clickNum: 0,
      goTimeInter: null,
      goTime: 30,
    };
  },
  //自定义指令
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        oDiv.onmousedown = (e) => {
          console.log("onmousedown");
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;
          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;

            oDiv.style.left = left + "px";
            oDiv.style.top = top + "px";
          };

          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },

  created() {
    this.netBarName = cMethods.GetNetBarName();
    this.netBarId = cMethods.GetNetBarID();
    this._GetList();

    // this.netBarName = "一级网吧";
    // this.netBarId = "8843";
    // this.netBarName = "啪啪网吧";
    // this.netBarId = "7743";
    // this.netBarName = "几脚路";
    // this.netBarId = "8854";
    // this.netBarName = "CCC的网吧1"; 
    // this.netBarId = "8792";
    // this.netBarName = "多多测试";
    // this.netBarId = "17287";
    // this.netBarName = "波比网吧";
    // this.netBarId = "8861";
    // this.netBarName = "啦啦啦";
    // this.netBarId = "8866";
    // this.netBarName = "心悦网吧";
    // this.netBarId = "7737";
    // this.netBarName = "杰拉网咖杭州上城区闸弄口三里亭店";
    // this.netBarId = "7737";
    // this.netBarName = "测试网吧";
    // this.netBarId = "8850";

    // 存入store
    this.$store.commit("SET_NETBAR_NAME", this.netBarName);
    this.$store.commit("SET_NETBAR_ID", this.netBarId);

    if (this.netBarId && this.netBarName) {
      this._GetSteamPower();
    } else {
      // 获取机器码
      let Machinecode = cMethods.FormGetMachineCode(); // || "123456"
      _czc.push(["_trackEvent", "没有获取网吧Key：【" + Machinecode + "】", "提示框", "展现量"]);
    }

    // 启动游戏倒计时
    eventBus.$on("getTimeGlob", () => {
      clearInterval(this.goTimeInter);
      this.goTimeInter = setInterval(() => {
        console.log(this.goTime);
        if (this.goTime > 0) {
          this.goTime--;
        } else {
          this.goTime = 30;
          clearInterval(this.goTimeInter);
        }
      }, 1000);
    });
  },
  mounted() {
    this._GetVerifyGame();
    // 挂载VUE
    // window.Fn = this;
    // window.vue = this;
    window.toUse = this.toUse;
  },
  methods: {
    // 获取页面广告
    async _GetList() {
      let res = await GetList();
      if (res.StatusCode == 200) {
        let result = JSON.stringify(res.Object);
        sessionStorage.setItem("adList", result);
      }
    },
    toUse() {
      this.$router.push("/userCenter/exchange");
    },
    // 获取网吧版本
    async _GetSteamPower() {
      let params = {
        netbarId: this.netBarId,
      };
      let res = await GetSteamPower(params);
      if (res.Object) {
        this.$store.commit("SET_VIP", true);
        this.$store.commit("SET_NETBAR_VIP_ENDTIME", res.Object.ExpireDateTime);
        _czc.push(["_trackEvent", "钻石版", "网页", "访问量"]);
      } else {
        if (this.isLogin && this.userInfo) {
          let params = {
            userId: this.userInfo.ConsumerId,
            state: 1,
            pageSize: 999,
            pageIndex: 1,
          };
          let res = await GetUserWelfareLogs(params);
          if (res.Items) {
            let num = 0;
            num = res.Items.length;
            if (num > 0) {
              this.$notify({
                title: "温馨提示",
                dangerouslyUseHTMLString: true,
                message:
                  `您有${num}张券待使用` +
                  "<el-button type='primary' onclick='toUse()' id='toUse'>立即使用</el-button>",
                duration: 0,
                type: "default",
                position: "bottom-right",
              });
            }
          }
        }

        _czc.push(["_trackEvent", "黄金版", "网页", "访问量"]);
      }
    },
    // 收起/展开左边菜单
    packUp() {
      this.isPackUp = !this.isPackUp;
    },
    // 获取高端游戏
    async _GetVerifyGame() {
      let res = await GetVerifyGame();
      if (res.StatusCode == 200) {
        this.$store.commit("setHighGames", res.Object);
      }
    },
    // 备份存档
    backups(gameId, steamId) {
      if (this.isLogin && this.userInfo) {
        cMethods.Form_jsUploadArchiveForTQ(
          this.userInfo.ConsumerId,
          gameId,
          steamId
        );
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // // 关闭游戏存档
    // closeDialog() {
    //   this.showGameNameDialog = false;
    //   cMethods.FormJsGetArchiveCustomFile(0, "");
    //   console.log("关闭");
    // },
    // // 开启游戏存档
    // showDialog() {
    //   this.form.gameName = "";
    //   this.showGameNameDialog = true;
    // },
    // // 保存存档
    // submit() {
    //   if (!this.form.gameName) {
    //     this.$message({
    //       type: "error",
    //       message: "请输入存档名称",
    //     });
    //     return false;
    //   }
    //   cMethods.FormJsGetArchiveCustomFile(1, this.form.gameName);
    //   this.showGameNameDialog = false;
    //   console.log("确定");
    // },
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  box-sizing: border-box;
}

#app {
  width: 1200px;
  height: 800px;
  background-size: 100% 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  @include app_bg_col();
  box-shadow: 1px 1px 5px #888888;
}

.el-header {
  height: 84px !important;
  padding: 0 !important;
  // @include header_bg_color();
  background-size: 100% 100%;
  @include font_col3();
  box-sizing: border-box;
}

.el-aside {
  height: 716px !important;
  overflow: visible !important;
  position: relative;
  box-sizing: border-box;

  .drawer {
    width: 18px;
    height: 100px;
    line-height: 100px;
    position: absolute;
    top: 50%;
    right: -18px;
    margin-top: -50px;
    // z-index: 2000;
    border: 1px solid #ccc;
    border-radius: 0 5px 5px 0;
    border-left: none;
    text-align: center;
    color: #8da6ba;
    display: none;
    cursor: pointer;
    font-size: 18px;
    background: $white-col;
  }
}

.el-aside:hover .drawer {
  display: block;
}

.el-main {
  padding: 0 !important;
}

.main-wrapper {
  width: 1040px;
  height: 676px;
  margin: 0 auto;
  padding: 20px;
  overflow: auto;
}

.gameNameDialog {
  .el-dialog__body {
    .el-input {
      width: 230px;
    }
  }

  .el-button--default:focus,
  .el-button--default:hover {
    color: #ff3636;
    border-color: #ff3636;
    background-color: transparent;
  }

  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #ff3636;
  }
}

.fixed-box {
  cursor: move;
  position: relative;

  .game-img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    border: 4px solid rgba(255, 106, 104, 0.5);
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      display: block;
      pointer-events: none;
    }

    .conduct {
      width: 72px;
      height: 72px;
      line-height: 72px;
      text-align: center;
      border-radius: 36px;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      z-index: 1;
      background: rgba(0, 0, 0, 0.5);
    }

    // .conduct {
    //   margin-top: 5px;
    //   color: #000;
    // }
  }

  .game-info {
    padding-left: 75px;
    width: 185px;
    height: 62px;
    border-radius: 31px;
    background: rgba(255, 106, 104, 0.5);
    text-align: center;

    position: absolute;
    top: 9px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // align-items: center;
    .game-name {
      width: 160px;
      // display: flex;
      // justify-content: space-between;
      // margin-left: 40px;
      margin-bottom: 5px;
      color: #910000;
      font-size: 16px;
      text-align: center;
      // .name {
      // }
    }

    .el-button {
      // margin-right: 5px;
      width: 76px;
      height: 26px;
      background: #ff3636;
      color: #fff;
      border-radius: 13px;
      border: none;
    }
  }
}

#toUse {
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  background: red;
  margin-left: 30px;
  cursor: pointer;
}
</style>
